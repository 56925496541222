import React, { useRef, createContext, useCallback, useContext } from "react"
import { State, Router, ActivationFn } from "router5"

export type DeactivationFn = (
  toState: State,
  fromState: State
) => boolean | Promise<boolean> | undefined

const CanDeactivateContext = createContext<
  | Readonly<
      [
        (name: string, fn: DeactivationFn) => void,
        (name: string, fn: DeactivationFn) => void
      ]
    >
  | undefined
>(undefined)

type CanDeactivateProviderProps = {
  router: Router
  children: React.ReactNode
}

const CanDeactiveProvider = ({
  router,
  children,
}: CanDeactivateProviderProps) => {
  const fns = useRef<Record<string, DeactivationFn[]>>({})

  const register = useCallback(
    (name: string, fn: DeactivationFn) => {
      if (fns.current[name]) {
        // console.log(`Adding new route=${name} map`)
        const idx = fns.current[name].indexOf(fn)
        if (idx === -1) {
          // console.log(`Adding new function to route=${name} map`)
          fns.current[name].push(fn)
        }
      } else {
        // console.log(`Adding new function to route=${name} map 2`)
        fns.current[name] = [fn]
        const cb: ActivationFn = async (toState, fromState, _done) => {
          for (let i = 0; i < fns.current[name].length; i++) {
            const fn = fns.current[name][i]

            const res = fn(toState, fromState)
            if (res === undefined) {
              return false
            }
            if (typeof res === "boolean") {
              if (res === false) {
                return false
              }
            } else {
              const b = await res
              if (b === false) {
                return false
              }
            }
          }
          return true
        }
        router.canDeactivate(name, () => cb)
      }
    },
    [router]
  )

  const unregister = useCallback(
    (name: string, fn: DeactivationFn) => {
      if (fns.current[name]) {
        // console.log(`unregistering for route=${name}`)
        const idx = fns.current[name].indexOf(fn)
        if (idx === -1) {
          throw Error("this should not happen")
        }

        fns.current[name].splice(idx, 1)

        if (fns.current[name].length === 0) {
          // console.log(`Route map name=${name} is empty, removing`)
          router.clearCanDeactivate(name)
          delete fns.current[name]
        }
      } else {
        throw Error("this should not happen")
      }
    },
    [router]
  )

  const value = [register, unregister] as const
  return (
    <CanDeactivateContext.Provider value={value}>
      {children}
    </CanDeactivateContext.Provider>
  )
}

const useCanDeactivateCallback = () => {
  const context = useContext(CanDeactivateContext)
  if (context === undefined) {
    throw new Error(
      "useCanDeactivate must be used within a CanDeactivateProvider"
    )
  }
  return context
}

export { CanDeactiveProvider, useCanDeactivateCallback }
