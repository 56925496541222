import { ThemeProvider, createTheme } from "@mui/material/styles"
import { CircularProgress, Box, Typography } from "@mui/material"

import { configure } from "mobx"
import { Suspense } from "react"
import { useRoute, RouterProvider } from "react-router5"
import { observer } from "mobx-react-lite"

import { Shell } from "@/base/layout"
import { SideMenu, Logo } from "@/layout"
import routes from "@/routes"
import configureRouter, { RouteView } from "@/base/routing"
import { BaseStoresProvider, baseStores } from "@/base/stores"
import { AppStoresProvider, appStores, useAppStores } from "@/stores"
import { CanDeactiveProvider } from "@/base/routing/CanDeactivateProvider"
import LoginScreen from "@/base/layout/LoginScreen"

configure({ enforceActions: "observed" })

const theme = createTheme({
  typography: {
    fontWeightRegular: 700,
    fontWeightMedium: 700,
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },

    MuiTableCell: {
      defaultProps: {
        sx: { fontSize: "1rem" },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        margin: "dense",
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: "dense",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
    },
  },
})

const router = configureRouter("menu-dashboard", true)
router.start()

const Main = observer(() => {
  const route = useRoute()
  const { appStore } = useAppStores()
  // const { uiStore } = useBaseStores()

  if (!baseStores.userStore.currentUser) {
    return <LoginScreen logo={<Logo />} />
  }

  const fullyLoaded = appStore.fullyLoaded
  // const showNotification =
  //   uiStore.topNotification && !uiStore.topNotification.closedAt

  return (
    <Shell sideMenu={<SideMenu />} logo={<Logo />}>
      {fullyLoaded ? (
        <Suspense fallback={<div>Loading...</div>}>
          <RouteView route={route.route} routes={routes} />
        </Suspense>
      ) : (
        <Box display="flex" width="100%" height="50vh">
          <Box sx={{ m: "auto" }}>
            <Typography variant="h6">Loading Data...</Typography>
            <CircularProgress size={80} sx={{ ml: 2, mt: 2 }} />
          </Box>
        </Box>
        // <>
        //   {/* <div
        //     style={{
        //       zIndex: 2000,
        //       opacity: 0.2,
        //       height: "100vh",
        //       width: "100vw",
        //       position: "fixed",
        //       top: 0,
        //       left: 0,
        //       backgroundColor: "black",
        //     }}
        //   /> */}
        //   <Box
        //     display="flex"
        //     width="100%"
        //     height={`calc(100vh - ${showNotification ? 96 : 48}px)`}
        //   >
        //     {/* <Box
        //       sx={{
        //         m: "auto",
        //         px: 4,
        //         py: 2,
        //         border: "2px solid black",
        //         borderRadius: 5,
        //         textAlign: "center",
        //         boxShadow: "3px 3px 5px",
        //         backgroundColor: "white",
        //         zIndex: 2001,
        //       }}
        //     > */}
        //     <Typography variant="h6">Loading Data...</Typography>
        //     <CircularProgress size={80} sx={{ mt: 2 }} />
        //   </Box>
        //   {/* </Box> */}
        // </>
      )}
    </Shell>
  )
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BaseStoresProvider value={baseStores}>
        <AppStoresProvider value={appStores}>
          <RouterProvider router={router}>
            <CanDeactiveProvider router={router}>
              <Main />
            </CanDeactiveProvider>
          </RouterProvider>
        </AppStoresProvider>
      </BaseStoresProvider>
    </ThemeProvider>
  )
}

export default App
