import { flow, makeAutoObservable, when } from "mobx"
import { CancelTokenSource } from "axios"
import * as Sentry from "@sentry/react"

import Api, {
  GET_LIST,
  Sort,
  Pagination,
  QueryParams,
  Filter,
  isCancel,
  NetworkError,
} from "../api"
import { Data, UserStore } from "."

export type { Sort, Pagination, Filter }

export class ListStore<T extends Data = Data> {
  api: Api
  userStore: UserStore
  resource: string
  data: T[] = []

  total = 0

  selectedId?: Data["id"]
  cancelToken?: CancelTokenSource

  constructor(
    api: Api,
    userStore: UserStore,
    resource: string,
    private onLoading?: (loading: boolean) => void
  ) {
    this.api = api
    this.userStore = userStore
    this.resource = resource

    makeAutoObservable(this, {
      api: false,
      resource: false,
      total: false,
      selectedId: false,
      cancelToken: false,
      cancel: false,
    })
  }

  reset(data: T[] = []) {
    this.data = data
    this.selectedId = undefined
    this.total = 0
  }

  get empty() {
    return !this.data || this.data.length === 0
  }

  fetch = flow(function* (
    this: ListStore<T>,
    params: QueryParams,
    doStore = true
  ) {
    let retries = 2
    while (retries--) {
      try {
        this.onLoading?.(true)
        this.cancel()

        const { promise, source } = this.api.call(
          GET_LIST,
          this.resource,
          params
        )

        this.cancelToken = source
        const result = yield promise
        this.cancelToken = undefined

        this.onLoading?.(false)

        this.total = result.total
        // this.params = params

        const d = result.data as T[]
        if (doStore) {
          this.data = d
        }
        const res = {
          data: d,
          total: this.total,
        }
        return res
      } catch (error) {
        const err = error as NetworkError

        if (isCancel(err)) {
          this.cancelToken = undefined
          this.onLoading?.(false)
          return this.data
        }

        this.cancelToken = undefined
        this.onLoading?.(false)

        if (
          err.response &&
          err.response.data.code &&
          err.response.data.message
        ) {
          if (err.response.data.code < 500) {
            err.message = err.response.data.message
            err.issues = err.response.data?.issues
          }
        }

        const status = err.response && err.response.status
        switch (status) {
          case 401:
            if (retries > 0) {
              const success = yield this.api.refreshToken()
              if (!success) {
                this.userStore.setCurrentUser(undefined)
                yield when(() => this.userStore.currentUser !== undefined)
              }
            } else {
              throw error
            }
            break

          default:
            console.dir(err)
            Sentry.withScope((scope) => {
              if (err.response && err.response.data) {
                scope.setExtra("Data", err.response.data)
              }
              Sentry.captureException(err)
            })
            throw err
        }
      }
    }
  })

  cancel() {
    if (this.cancelToken) {
      console.log("canceled")
      this.cancelToken.cancel()
      this.cancelToken = undefined
    }
  }
}
