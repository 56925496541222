import {
  Tabs,
  Tab,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material"
import { MoreVert as MoreVertIcon } from "@mui/icons-material"
import { useRouter } from "react-router5"
import { observer } from "mobx-react-lite"

import { useBaseStores } from "../stores"
import { useState } from "react"

const Breadcrumbs = () => {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up("md"))
  return isLarge ? <BreadcrumbsTabs /> : <BreadcrumbsMenu />
}

export default Breadcrumbs

const BreadcrumbsTabs = observer(() => {
  const router = useRouter()
  const { breadcrumbStore } = useBaseStores()
  return (
    <Tabs
      sx={{ flexGrow: 1 }}
      value={
        breadcrumbStore.currentBreadcrumb
          ? breadcrumbStore.currentBreadcrumb.state.name
          : false
      }
      indicatorColor="primary"
      TabIndicatorProps={{ style: { height: 5 } }}
      onChange={(event, value) => router.navigate(value, { id: "dummy" })}
    >
      {breadcrumbStore.breadcrumbs.map((b) => (
        <Tab
          key={b.state.name}
          value={b.state.name}
          label={
            <span style={{ display: "flex" }}>
              <Box
                component="span"
                sx={{
                  color: b.data.dirty ? "salmon" : "white",
                  marginBottom: "-5px",
                  marginRight: "5px",
                }}
              >
                {b.data.icon}
              </Box>
              <Box
                component="span"
                sx={{
                  color: b.data.dirty ? "salmon" : "white",
                  marginTop: "5px",
                }}
              >
                {b.data.name}
              </Box>
            </span>
          }
        />
      ))}
    </Tabs>
  )
})

const BreadcrumbsMenu = observer(() => {
  const router = useRouter()
  const { breadcrumbStore } = useBaseStores()

  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined)

  const current = breadcrumbStore.currentBreadcrumb
  const dirty = breadcrumbStore.breadcrumbs.filter((item) => item.data.dirty)

  const handleClick = (stateName: string) => {
    router.navigate(stateName, { id: "dummy" })
    setAnchorEl(undefined)
  }

  return (
    <Box display="flex" flexGrow={1} justifyContent="space-between" mx={1}>
      <span style={{ display: "flex" }}>
        <Box
          component="span"
          sx={{
            color: current?.data.dirty ? "salmon" : "white",
            marginLeft: 1,
            marginTop: 0.5,
          }}
        >
          {current?.data.icon}
        </Box>
        <Box
          component="span"
          sx={{
            color: current?.data.dirty ? "salmon" : "white",
            marginTop: 0.7,
            marginLeft: 0.8,
            textTransform: "uppercase",
          }}
        >
          {current?.data.name}
        </Box>
      </span>

      <IconButton
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Badge
          badgeContent={breadcrumbStore.breadcrumbs.length}
          color={dirty.length === 0 ? "primary" : "error"}
        >
          <MoreVertIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
        {breadcrumbStore.breadcrumbs.map((item) => {
          return (
            <MenuItem
              key={item.state.name}
              onClick={() => handleClick(item.state.name)}
            >
              <span
                style={{
                  color: item.data.dirty ? "salmon" : undefined,
                }}
              >
                {item.data.icon}
              </span>
              <span
                style={{
                  textTransform: "uppercase",
                  color: item.data.dirty ? "salmon" : undefined,
                }}
              >
                {item.data.name || ""}
              </span>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
})
