import axios, { AxiosRequestConfig, AxiosError } from "axios"

import fetch, { FetchParams } from "./rest-layer"

export * from "./common"
export { isCancel, isAxiosError } from "./rest-layer"

export interface NetworkError extends AxiosError {
  issues?: string[]
}

class Api {
  apiUrl: string
  private rTokenPromise?: Promise<string>

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl
  }

  setRefreshToken = (token: string) => {
    localStorage.setItem("auth-refreshToken", token)
  }

  get = (url: string, config: AxiosRequestConfig) =>
    axios.get(`${this.apiUrl}/api/${url}`, { ...config, withCredentials: true })

  post = (
    url: string,
    data: Record<string, unknown> | FormData,
    config?: AxiosRequestConfig
  ) =>
    axios.post(`${this.apiUrl}/api/${url}`, data, {
      ...config,
      withCredentials: true,
    })

  put = (
    url: string,
    data: Record<string, unknown> | FormData,
    config?: AxiosRequestConfig
  ) =>
    axios.put(`${this.apiUrl}/api/${url}`, data, {
      ...config,
      withCredentials: true,
    })

  login = (username: string, password: string) =>
    axios.post(
      `${this.apiUrl}/login`,
      username && password ? { username, password } : undefined,
      { withCredentials: true }
    )

  logout = () => {
    const token = localStorage.getItem("auth-refreshToken")
    axios.post(`${this.apiUrl}/logout`, undefined, {
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  }

  refreshToken = async () => {
    if (this.rTokenPromise) {
      try {
        await this.rTokenPromise
      } catch (error) {
        return false
      }
      return true
    }

    this.rTokenPromise = new Promise<string>((resolve, reject) => {
      const token = localStorage.getItem("auth-refreshToken")
      axios
        .post(
          `${this.apiUrl}/refresh_token`,
          {},
          {
            withCredentials: true,
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((result) => resolve(result.data.refreshToken))
        .catch((error) => reject(error))
    })

    try {
      const token = await this.rTokenPromise
      this.setRefreshToken(token)
    } catch (error) {
      this.rTokenPromise = undefined
      return false
    }
    this.rTokenPromise = undefined

    return true
  }

  call = (type: string, resource: string, params: FetchParams) =>
    fetch(type, `${this.apiUrl}/api/${resource}`, params)
}

export default Api
