import { Component, ErrorInfo, cloneElement } from "react"

import * as Sentry from "@sentry/react"
import { State } from "router5"

import { RouteConfig } from "@/routes"
import getComponent from "./getComponent"

const RouteView = ({
  route,
  routes,
  ...rest
}: {
  route: State
  routes: RouteConfig[]
}) => {
  const component = getComponent(route.name, routes)
  return (
    <>
      {component ? (
        cloneElement(component, { ...rest })
      ) : (
        <h1>Waiting for server...</h1>
      )}
    </>
  )
}

type ErrorBoundaryProps = typeof ErrorBoundary.defaultProps & {
  routes: RouteConfig[]
  route: State
  errorMessage: string
  errorStyle: object
  errorMessage2?: string
  errorStyle2?: object
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  static defaultProps = {
    errorMessage: "Something went wrong.",
    errorStyle: { color: "rgb(217, 83, 79)" },
    errorMessage2:
      "We have been notified about this error. You can continue using the rest of the system.",
  }
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const currentRoute = this.props.route
    // Display fallback UI
    this.setState({ hasError: true })

    console.error(
      `RouteView: it was not possible to select the correct view for the current route '${currentRoute.name}' having params: `
    )
    // This outputs an object on the browser console you can click through
    console.dir(currentRoute.params)
    console.log(error, info)

    Sentry.captureException(error, { extra: { stack: info.componentStack } })
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1 style={this.props.errorStyle}>{this.props.errorMessage}</h1>
          {this.props.errorMessage2 && (
            <h3 style={this.props.errorStyle2}>{this.props.errorMessage2}</h3>
          )}
        </>
      )
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { errorMessage, errorStyle, ...passThroughProps } = this.props
    return <RouteView {...passThroughProps} />
  }
}

ErrorBoundary.defaultProps = {
  errorMessage: "Something went wrong.",
  errorStyle: { color: "rgb(217, 83, 79)" },
  errorMessage2:
    "We have been notified about this error. You can continue using the rest of the system.",
}

export default ErrorBoundary
