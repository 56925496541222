import { SvgIcon, SvgIconProps } from "@mui/material"

const icon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 980 782"
    style={{ transform: "scale(-1,1)" }}
  >
    <g
      transform="translate(0.000000,782.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M4252 7785 c-105 -27 -252 -93 -338 -153 -35 -24 -840 -822 -1960
-1944 -2138 -2140 -1948 -1935 -1949 -2103 0 -81 3 -97 33 -160 32 -67 93
-130 1675 -1711 1861 -1861 1684 -1699 1862 -1699 147 0 189 23 365 200 l135
135 -1618 1618 -1617 1617 2112 2112 2113 2113 -360 0 c-346 -1 -363 -2 -453
-25z"
      />
      <path
        d="M6285 7795 c-87 -19 -231 -75 -321 -125 -74 -41 -196 -161 -1991
-1954 -1313 -1312 -1920 -1924 -1937 -1955 -32 -61 -50 -156 -42 -224 17 -131
-84 -24 1722 -1828 1871 -1869 1680 -1694 1859 -1694 93 0 104 3 160 32 51 27
342 314 1966 1940 1701 1702 1911 1916 1949 1981 50 88 93 190 123 292 l22 75
3 1575 c2 1556 2 1576 -18 1640 -35 115 -114 198 -226 240 -54 20 -70 20
-1632 19 -1259 0 -1589 -3 -1637 -14z m1427 -1490 c312 -66 544 -317 588 -636
20 -145 -8 -300 -81 -439 -93 -179 -252 -311 -449 -372 -109 -33 -312 -33
-420 0 -234 74 -409 239 -491 462 -154 424 103 889 545 985 81 18 225 18 308
0z"
      />
    </g>
  </SvgIcon>
)

export default icon
