import { SvgIcon, SvgIconProps } from "@mui/material"

const icon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 788 980">
    <g
      transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M2030 9360 l0 -440 255 0 255 0 0 185 0 185 1995 0 1995 0 0 -400 0
-400 420 0 420 0 -2 -2882 -3 -2883 -112 -3 -113 -3 0 -249 0 -250 365 0 365
0 0 3264 0 3265 -542 525 -542 526 -2378 0 -2378 0 0 -440z"
      />
      <path
        d="M990 8305 l0 -435 255 0 255 0 0 185 0 185 1995 0 1995 0 0 -400 0
-400 420 0 420 0 0 -2885 0 -2885 -110 0 -110 0 0 -255 0 -255 360 0 360 0 0
3268 0 3268 -537 522 -538 521 -2382 1 -2383 0 0 -435z"
      />
      <path
        d="M10 3790 l0 -3790 2920 0 2920 0 0 3264 0 3264 -542 526 -543 526
-2377 0 -2378 0 0 -3790z m4490 2890 l0 -400 420 0 420 0 0 -2890 0 -2890
-2412 2 -2413 3 -3 3275 c-1 1801 0 3281 3 3288 3 9 412 12 1995 12 l1990 0 0
-400z"
      />
      <path
        d="M1272 5479 c-62 -31 -95 -85 -100 -164 -8 -116 51 -195 160 -215 36
-7 597 -9 1634 -8 1709 3 1595 -1 1657 57 44 40 60 80 61 146 1 74 -23 126
-77 169 l-39 31 -1626 3 -1627 2 -43 -21z"
      />
      <path
        d="M1272 4519 c-62 -31 -95 -85 -100 -164 -8 -116 51 -195 160 -215 36
-7 597 -9 1634 -8 l1579 3 41 23 c137 79 131 299 -10 363 -39 18 -100 19
-1651 19 l-1610 0 -43 -21z"
      />
      <path
        d="M1272 3629 c-62 -31 -95 -85 -100 -164 -8 -116 51 -195 160 -215 36
-7 597 -9 1634 -8 l1579 3 41 23 c137 79 131 299 -10 363 -39 18 -100 19
-1651 19 l-1610 0 -43 -21z"
      />
      <path
        d="M1295 2679 c-38 -11 -94 -64 -111 -106 -19 -45 -17 -131 5 -178 20
-46 69 -91 109 -103 15 -4 752 -7 1637 -7 l1610 0 43 25 c136 78 129 297 -12
361 -40 18 -99 19 -1646 18 -895 -1 -1618 -5 -1635 -10z"
      />
      <path
        d="M1207 1759 c-20 -11 -49 -38 -65 -61 -23 -36 -27 -51 -27 -118 0 -67
3 -82 28 -119 18 -27 44 -50 70 -62 41 -18 92 -19 1654 -19 1115 0 1620 3
1640 11 34 12 80 55 98 89 53 103 2 251 -98 289 -20 8 -528 11 -1645 11 -1592
-1 -1618 -1 -1655 -21z"
      />
    </g>
  </SvgIcon>
)

export default icon
