import { lazy, ReactElement } from "react"
import { Route } from "router5"

const Dashboard = lazy(() => import("./pages/Dashboard"))
const Offers = lazy(() => import("./pages/offers"))
const Customers = lazy(() => import("./pages/customers"))
const Contracts = lazy(() => import("./pages/contracts"))
const Settings = lazy(() => import("./pages/Settings"))
const Customer = lazy(() => import("./pages/customer"))
const Contract = lazy(() => import("./pages/Contract"))
const Offer = lazy(() => import("./pages/offer"))
const System = lazy(() => import("./pages/Settings/System"))
const Users = lazy(() => import("./pages/Settings/Users"))
const Suppliers = lazy(() => import("./pages/Settings/Suppliers"))
const SupplierView = lazy(() => import("./pages/Settings/SupplierView"))
const UserView = lazy(() => import("./pages/Settings/UserView"))
const Payments = lazy(() => import("./pages/PaymentsDue"))
const PaymentCalendar = lazy(() => import("./pages/PaymentCalendar"))
const Invoices = lazy(() => import("./pages/Invoices"))
const Invoice = lazy(() => import("./pages/Invoice"))
const Receipt = lazy(() => import("./pages/Receipt"))
const Reports = lazy(() => import("./pages/Reports"))
const Portfolio = lazy(() => import("./pages/Reports/Portfolio"))
const InvoicesReport = lazy(() => import("./pages/Reports/Invoices"))
const ReceiptsReport = lazy(() => import("./pages/Reports/Receipts"))
const AgeAnalisysReport = lazy(() => import("./pages/Reports/AgeAnalisys"))

export interface RouteConfig extends Route {
  component: ReactElement
  children?: Array<RouteConfig>
  helpUrl?:
    | string
    | {
        default: string
        [tab: string]: string
      }
}

const routes: RouteConfig[] = [
  { name: "menu-dashboard", path: "/dashboard", component: <Dashboard /> },
  {
    name: "menu-offers",
    path: "/offers?filters&list",
    component: <Offers />,
  },
  {
    name: "menu-payments",
    path: "/payments?filters&list",
    component: <Payments />,
  },
  { name: "menu-customers", path: "/customers?list", component: <Customers /> },
  {
    name: "menu-contracts",
    path: "/contracts?filters&list",
    component: <Contracts />,
  },
  {
    name: "menu-invoices",
    path: "/invoices?filters&list",
    component: <Invoices />,
  },
  {
    name: "menu-reports",
    path: "/reports",
    component: <Reports />,
    children: [
      {
        name: "portfolio-view",
        path: "/portfolio?model",
        component: <Portfolio reportItem="remaining-capital" />,
      },
      {
        name: "interest-income-view",
        path: "/interest-income?model",
        component: <Portfolio reportItem="interest-income" />,
      },
      {
        name: "rental-capital-view",
        path: "/rental-capital?model",
        component: <Portfolio reportItem="rental-capital" />,
      },
      {
        name: "rentals-ex-vat-view",
        path: "/rentals-ex-vat?model",
        component: <Portfolio reportItem="rentals-ex-vat" />,
      },
      {
        name: "rentals-inc-vat-view",
        path: "/rentals-inc-vat?model",
        component: <Portfolio reportItem="rentals-inc-vat" />,
      },
      {
        name: "vat-view",
        path: "/vat?model",
        component: <Portfolio reportItem="vat" />,
      },
      {
        name: "invoices-view",
        path: "/invoices?model",
        component: <InvoicesReport />,
      },
      {
        name: "tenor-view",
        path: "/tenor?model",
        component: <Portfolio reportItem="tenor" />,
      },
      {
        name: "receipts-view",
        path: "/receipts?model",
        component: <ReceiptsReport />,
      },
      {
        name: "age-analisys-view",
        path: "/age-analisys?model",
        component: <AgeAnalisysReport />,
      },
    ],
  },
  {
    name: "menu-settings",
    path: "/settings",
    component: <Settings />,
    children: [
      { name: "system-view", path: "/system", component: <System /> },
      {
        name: "users-list",
        path: "/users",
        component: <Users />,
        children: [
          { name: "user-view", path: "/:id", component: <UserView /> },
        ],
      },
      {
        name: "suppliers-list",
        path: "/supliers",
        component: <Suppliers />,
        children: [
          { name: "supplier-view", path: "/:id", component: <SupplierView /> },
        ],
      },
    ],
  },

  { name: "customer-view", path: "/customers/:id", component: <Customer /> },
  {
    name: "contract-view",
    path: "/contracts/:id?tab&tabCash",
    component: <Contract />,
  },
  { name: "offer-view", path: "/offer/:id?tab", component: <Offer /> },
  {
    name: "paymentCalendar-view",
    path: "/paymentCalendar/:id?tab",
    component: <PaymentCalendar />,
  },
  {
    name: "invoice-view",
    path: "/invoices/:id?tab",
    component: <Invoice />,
  },
  {
    name: "receipt-view",
    path: "/receipts/:id?tab",
    component: <Receipt />,
  },

  {
    name: "customer-search",
    path: "/customer-search",
    component: <Customers forSelect />,
  },
]

export default routes
