import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"

import {
  People,
  Dashboard,
  Settings,
  PointOfSale,
  Assessment,
} from "@mui/icons-material"
import { useRouter } from "react-router5"
import { observer } from "mobx-react-lite"

import { useBaseStores } from "@/base/stores"
import OffersIcon from "@/icons/Offers"
import ContractsIcon from "@/icons/Contracts"
import InvoiceIcon from "@/icons/Invoice"
import { useAppStores } from "@/stores"

const menu = [
  { text: "Dashboard", name: "menu-dashboard", icon: <Dashboard /> },
  { text: "Offers", name: "menu-offers", icon: <OffersIcon /> },
  { text: "Payments Due", name: "menu-payments", icon: <PointOfSale /> },
  { text: "Customers", name: "menu-customers", icon: <People /> },
  { text: "Contracts", name: "menu-contracts", icon: <ContractsIcon /> },
  {
    text: "Invoices",
    name: "menu-invoices",
    icon: <InvoiceIcon />,
  },
  {
    text: "Reports",
    name: "menu-reports",
    icon: <Assessment />,
    resource: "reports",
  },
  { text: "Settings", name: "menu-settings", icon: <Settings /> },
]

export const SideMenu = observer(({ open = true }: { open?: boolean }) => {
  const router = useRouter()
  const { breadcrumbStore } = useBaseStores()
  const { appStore } = useAppStores()
  return (
    <List>
      {menu.map((entry) => {
        if (entry.resource && !appStore.canRead(entry.resource, "*")) {
          // eslint-disable-next-line no-null/no-null
          return null
        }
        return (
          <ListItemButton
            key={entry.text}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            selected={breadcrumbStore.baseBreadcrumbName === entry.name}
            onClick={() =>
              router.navigate(entry.name, { __clearBreadcrumbs: true })
            }
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {entry.icon}
            </ListItemIcon>
            <ListItemText primary={entry.text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        )
      })}
    </List>
  )
})
