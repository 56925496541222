import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosError,
} from "axios"
import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
  PATCH,
  CREATE,
  DELETE,
  DELETE_MANY,
  formatQuery,
  QueryParams,
  Fields,
} from "./common"

export const isAxiosError = (error: any): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined
}

/**
 * Maps react-admin queries to a rest-layer REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=title&skip=0&limit=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */

// TODO: Conditional params structure, depending on the field values
type ConvertDataRequestParams = FetchParams & {
  fields?: Fields
}

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataRequestToHTTP = (
  type: string,
  resource: string,
  params: ConvertDataRequestParams
) => {
  let url = resource
  const config: AxiosRequestConfig = {
    timeout: params.timeout ?? 30000,
    headers: {
      // Disable browser caching for API calls
      "Cache-Control": "no-store, no-cache, max-age=0",
      ...params.headers,
    },
    withCredentials: true,
  }
  switch (type) {
    case GET_LIST: {
      config.params = { ...formatQuery(params), total: 1 }
      break
    }
    case GET_ONE:
      if (!params.id) {
        throw new Error(
          `No "id" supplied for ${type} on ${resource}, params = ${params}`
        )
      }
      if (params.fields) {
        const query = formatQuery(params)
        config.params = {
          fields: query.fields,
        }
      }
      url += `/${params.id}`
      break
    // case GET_MANY: {
    //   config.params = { ...formatQuery(params), total: 1 }
    //   break;
    // }
    // case GET_MANY_REFERENCE: {
    //   TODO
    //   const { page, perPage } = params.pagination;
    //   const { field, order } = params.sort;
    //   query = {
    //     sort: JSON.stringify([field, order]),
    //     range: JSON.stringify([
    //       (page - 1) * perPage,
    //       page * perPage - 1,
    //     ]),
    //     filter: JSON.stringify({
    //       ...params.filter,
    //       [params.target]: params.id,
    //     }),
    //   };
    //   config.params = query
    //   break;
    // }
    case UPDATE:
      if (!params.id) {
        throw new Error(
          `No "id" supplied for ${type} on ${resource}, params = ${params}`
        )
      }
      url += `/${params.id}`
      config.method = "put"
      config.data = params.data
      break
    case PATCH:
      if (!params.id) {
        throw new Error(
          `No "id" supplied for ${type} on ${resource}, params = ${params}`
        )
      }
      url += `/${params.id}`
      config.method = "patch"
      config.data = params.data
      break
    case CREATE:
      config.method = "post"
      config.data = params.data
      break
    case DELETE:
      if (!params.id) {
        throw new Error(
          `No "id" supplied for ${type} on ${resource}, params = ${params}`
        )
      }
      url += `/${params.id}`
      config.method = "delete"
      break
    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }
  return { url, config }
}

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} Data response
 */
const convertHTTPResponse = (response: AxiosResponse, type: string) => {
  const { headers } = response
  switch (type) {
    case GET_LIST:
      if (!headers["x-total"]) {
        throw new Error(
          "The X-Total header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?"
        )
      }
      return { ...response, total: parseInt(headers["x-total"], 10) }
    case CREATE:
      return response
    default:
      return response
  }
}

export type FetchParams = QueryParams & {
  id?: string
  ids?: string[]
  data?: Record<string, unknown> | Array<unknown>
  headers?: AxiosRequestHeaders
  timeout?: number
}

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
const fetch = (type: string, resource: string, params: FetchParams) => {
  // rest-layer doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  const source = axios.CancelToken.source()

  if (type === UPDATE_MANY) {
    if (!params.ids) {
      throw new Error(
        `No "ids" supplied for ${type} on ${resource}, params = ${params}`
      )
    }
    const promise = Promise.all(
      params.ids.map((id) =>
        axios.put(`${resource}/${id}`, {
          data: params.data,
          timeout: params.timeout ?? 30000,
          cancelToken: source.token,
          withCredentials: true,
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.data),
    }))
    return {
      promise,
      source,
    }
  }
  if (type === DELETE_MANY) {
    const query = {
      filter: { id: { $in: params.ids } },
    }
    const promise = axios
      .delete(`${resource}`, {
        params: query,
        timeout: params.timeout ?? 30000,
        cancelToken: source.token,
        withCredentials: true,
      })
      .then((response) => ({
        data: [response.data],
      }))
    return {
      promise,
      source,
    }
  }

  const { url, config } = convertDataRequestToHTTP(type, resource, params)
  config.cancelToken = source.token
  const promise = axios(url, config).then((response) =>
    convertHTTPResponse(response, type)
  )
  return {
    promise,
    source,
  }
}

export default fetch
export const isCancel = axios.isCancel
