import { makeAutoObservable, flow, reaction } from "mobx"
import { isEqual } from "lodash"

import { OfferForex, BankForex } from "@/models/Forex"

import {
  createItemStore,
  createListStore,
  baseStores,
  UserStore,
  UiStore,
} from "@/base/stores"
import { Decimal } from "@/models/common"
import SystemSettings, { Rebate } from "@/models/SystemSettings"
import UserModel from "@/models/User"
import Supplier from "@/models/Supplier"
import { EventBus } from "@/base/events"
import Insurer from "@/models/Insurer"
import { Engine, PermissionDefs } from "@/base/permissions"

const defaultRebateSettings: Rebate = {
  defaultPercent: "5",
  percentages: ["0", "5"],
  periodConfig: {
    monthly: {
      successivePayments: 12,
      delayedPayments: 4,
      delayedDays: 5,
    },
    default: {
      successivePayments: 4,
      delayedPayments: 1,
      delayedDays: 5,
    },
  },
}

const defaultInsurers: Insurer[] = [
  {
    id: "111111111111111111111111",
    name: "SWAN INSURANCE ZAMBIA",
    createdAt: "2022-12-01T12:00:00Z",
    params: {
      minValue: "515",
      defaultInsurancePercent: "0.70",
      defaultInsuranceLevyPercent: "3.00",
    },
  },
]

class AppStore {
  private engine?: Engine

  fullyLoaded = false

  settings: SystemSettings = {
    company: {
      name: "Company Name",
      address: "Company Address",
    },
    leasing: {
      defaultInterestRate: "28",
      calculationPrecision: 2,
      formatPrecision: 2,
    },
    vat: {
      vatPercent: "16",
      formatPrecision: 2,
    },
    forex: {
      formatPrecision: 4,
    },

    mainCurrency: "ZMW",
    bankForexSources: [
      {
        from: "USD",
        to: "ZMW",
      },
    ],

    _rebate: defaultRebateSettings,
  }

  users?: UserModel[]

  forexes: OfferForex[] = []
  bankForexes: BankForex[] = []
  currencySymbols: Record<string, string> = {
    USD: "$",
    ZMW: "K",
  }

  suppliers?: Supplier[]

  insurers: Insurer[]

  lastNewVersion?: string

  refreshAll() {
    if (!this.userStore.currentUser) {
      return
    }

    try {
      this.refreshBankForexes()
    } catch (error) {
      // console.log(error)
    }

    try {
      this.refreshForexes()
    } catch (error) {
      // console.log(error)
    }

    try {
      this.refreshSystemSettings()
    } catch (error) {
      // console.log(error)
    }

    try {
      this.refreshUsers()
    } catch (error) {
      // console.log(error)
    }
  }

  constructor(
    private eventBus: EventBus,
    private userStore: UserStore,
    private uiStore: UiStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.insurers = defaultInsurers

    eventBus.subscribe("AppStore", (event) => {
      switch (event.type) {
        case "user.save":
          this.refreshUsers()
          break
      }
    })

    reaction(
      () => this.settings,
      (settings) => {
        if (this.uiStore.topNotification?.closedAt) {
          const now = new Date()
          now.setDate(now.getDate() - 1)
          if (this.uiStore.topNotification.closedAt < now) {
            this.uiStore.topNotification.closedAt = undefined
          }
        }

        const curVersion =
          (import.meta.env.VITE_APP_VERSION as string | undefined) || ""
        const newVersion = settings._appVersion

        if (newVersion && newVersion !== curVersion) {
          const nv = newVersion.split(".")
          const cv = curVersion?.split(".")
          if (nv.length < 3 || cv.length < 3) {
            return
          }
          const major = nv[0] !== cv[0]
          let doShow = false
          if (newVersion !== this.lastNewVersion) {
            this.lastNewVersion = newVersion
            doShow = true
          }

          let html = `System current version is "<b>${curVersion}</b>", but there is new version "<b>${newVersion}</b>". `
          if (major) {
            html += `<b>System may not function properly. Close all system browser tabs, and reopen again to update.</b>`
          } else {
            html += `Close all system browser tabs, and reopen again to update.`
          }
          this.uiStore.setTopNotification({
            html,
            kind: major ? "error" : "warning",
            closedAt:
              major || doShow ? undefined : uiStore.topNotification?.closedAt,
          })
        } else {
          this.uiStore.setTopNotification(undefined)
        }
      }
    )

    this.refreshAll()

    setInterval(() => {
      this.refreshAll()
    }, 10 * 60 * 1000)
  }

  destroy() {
    this.eventBus.unsubscribe("AppStore")
  }

  refreshBankForexes = flow(function* (this: AppStore) {
    const store = createListStore<BankForex>("bankForexes", true)

    const forexes = []
    for (let i = 0; i < this.settings.bankForexSources.length; i++) {
      const src = this.settings.bankForexSources[i]
      const forex = yield store.fetch({
        filter: { from: src.from, to: src.to },
        pagination: { page: 0, perPage: 1 },
        sort: [{ name: "createdAt", direction: "desc" }],
      })
      if (forex.data.length > 0) {
        forexes.push(forex.data[0])
      }
    }
    this.bankForexes = forexes
    return this.bankForexes
  })

  refreshForexes = flow(function* (this: AppStore) {
    const store = createListStore<OfferForex>("forexes", true)

    const forexes = []
    for (let i = 0; i < this.settings.bankForexSources.length; i++) {
      const src = this.settings.bankForexSources[i]
      const forex = yield store.fetch({
        filter: { from: src.from, to: src.to },
        pagination: { page: 0, perPage: 1 },
        sort: [{ name: "updatedAt", direction: "desc" }],
      })
      if (forex.data.length > 0) {
        forexes.push(forex.data[0])
      }
    }
    this.forexes = forexes
    return this.forexes
  })

  refreshSystemSettings = flow(function* (this: AppStore) {
    const store = createListStore<SystemSettings>("systemSettings", true)

    const result = yield store.fetch({
      fields: [
        "*",
        {
          name: "suppliers",
        },
      ],
    })

    const settings = result.data[0]

    if (result.data.length > 0) {
      this.settings = settings
      this.suppliers = settings.suppliers
      delete this.settings.suppliers
    }

    this.settings._rebate = defaultRebateSettings

    return this.settings
  })

  refreshUsers = flow(function* (this: AppStore) {
    const store = createListStore<UserModel>("users", true)

    const result = yield store.fetch({})
    if (result.data.length > 0) {
      this.users = result.data
    }

    const userPermissions = PermissionDefs[this.currentUserRole()]
    if (
      !this.engine ||
      !isEqual(this.engine.getPermissions(), userPermissions)
    ) {
      this.engine = new Engine(userPermissions)
    }

    this.fullyLoaded = true

    return this.users
  })

  setSuppliers = (suppliers: Supplier[] | undefined) => {
    this.suppliers = suppliers
  }

  getUser(id: string | undefined) {
    return this.users?.find((u) => u.id === id)
  }

  private OVERRIDE_ALLOW = false
  canRead(resource: string, path: string) {
    if (this.OVERRIDE_ALLOW) {
      return true
    }

    if (this.currentUserRole() === "owner") {
      return true
    }
    return this.engine?.canRead(resource, path)
  }
  canWrite(resource: string, path: string) {
    if (this.OVERRIDE_ALLOW) {
      return true
    }

    if (this.currentUserRole() === "owner") {
      return true
    }
    return this.engine?.canWrite(resource, path)
  }
  canCreate(resource: string, path: string) {
    if (this.OVERRIDE_ALLOW) {
      return true
    }

    if (this.currentUserRole() === "owner") {
      return true
    }
    return this.engine?.canCreate(resource, path)
  }

  canReadWrite(resource: string, path: string) {
    return this.canCreate(resource, path) || this.canWrite(resource, path)
  }

  canSave(resource: string) {
    if (this.OVERRIDE_ALLOW) {
      return true
    }

    if (this.currentUserRole() === "owner") {
      return true
    }
    return this.engine?.canSave(resource)
  }

  currentUser() {
    return this.getUser(this.userStore.currentUser?.id)
  }
  currentUserRole() {
    return this.getUser(this.userStore.currentUser?.id)?.role || "officer"
  }

  updateForex = flow(function* (
    this: AppStore,
    value: Decimal,
    bankForex: BankForex
  ) {
    const forex: OfferForex = {
      from: bankForex.from,
      to: bankForex.to,
      value: value,
      baseBy: bankForex.by,
      baseUpdatedAt: bankForex.createdAt,
      baseValue: bankForex.value,
      userId: this.userStore.currentUser!.id,
    }
    const store = createItemStore<OfferForex>("forexes")
    const result = yield store.save(forex)
    this.forexes = this.forexes.map((f) => {
      if (f.from === forex.from && f.to === forex.to) {
        return result
      }
      return f
    })
  })

  updateBankForex = flow(function* (this: AppStore, bankForex: BankForex) {
    const store = createItemStore<BankForex>("bankForexes")
    const result = yield store.save(bankForex)
    this.bankForexes = this.bankForexes.map((f) => {
      if (f.from === bankForex.from && f.to === bankForex.to) {
        return result
      }
      return f
    })
  })

  updateSystemSettings = flow(function* (
    this: AppStore,
    settings: SystemSettings
  ) {
    const store = createItemStore<SystemSettings>("systemSettings")
    store.reset(this.settings)

    const result = yield store.save(settings)
    this.settings = result
    return result
  })

  login = flow(function* (this: AppStore, username = "", password = "") {
    try {
      const result = yield baseStores.api.login(username, password)
      this.userStore.setCurrentUser(result.data.user)

      baseStores.api.setRefreshToken(result.data.refreshToken)

      yield this.refreshAll()
    } catch (error) {
      this.userStore.currentUser = undefined
      throw error
    }
  })

  logout = flow(function* (this: AppStore) {
    try {
      yield baseStores.api.logout()
    } catch (error) {
      console.log("logout", error)
    }
    this.userStore.setCurrentUser(undefined)
  })
}

export default AppStore
