import { makeAutoObservable, toJS } from "mobx"
import React from "react"
import { State } from "router5"

import { EventAcceptor, Event } from "@/events"

export interface StateData {
  name: string
  dirty?: boolean
  icon?: React.ReactElement
  onDestroy?: () => void
  meta?: EventAcceptor
}

type Breacrumb = {
  state: State
  data: StateData
}

export class BreadcrumbStore {
  breadcrumbs: Breacrumb[] = []

  constructor() {
    makeAutoObservable(this, {}, { deep: false })
  }

  addOrUpdateBreadcrumb(state: State, data: StateData) {
    let changed = false
    const b = this.breadcrumbs.map((item) => {
      if (item.state.name === state.name) {
        changed = true
        return { state, data }
      } else {
        return item
      }
    })
    this.breadcrumbs = changed ? b : [...this.breadcrumbs, { state, data }]
  }

  updateBreadcrumbRoute(state?: State) {
    if (!state) {
      return
    }

    let changed = false
    const b = this.breadcrumbs.map((item) => {
      if (item.state.name === state.name) {
        changed = true
        return { state, data: item.data }
      } else {
        return item
      }
    })
    if (changed) {
      this.breadcrumbs = b
    }
  }

  getBreadcrumbIndex(state: State) {
    if (state.params?.__clearBreadcrumbs) {
      return 0
    }
    for (let i = this.breadcrumbs.length - 1; i >= 0; i--) {
      const bc = this.breadcrumbs[i]
      if (bc.state.name === state.name) {
        return i
      }
    }
    return -1
  }

  focusBreadcrumbOrAdd(state: State, data: StateData, isForward: boolean) {
    // Obtain initial breadcrumps length, becase later we are slicing it
    const lastBreadcrumb = this.breadcrumbs.length - 1

    for (let i = lastBreadcrumb; i >= 0; i--) {
      const bc = this.breadcrumbs[i]
      // Try find state with the same name, or state that is dirty. However we ignore the last(most recent) breadcrumb state,
      // because it is state's responsibility to trigger `Loose modifications` dialog
      if (
        bc.state.name === state.name ||
        (!isForward && bc.data.dirty && i !== lastBreadcrumb)
      ) {
        return toJS(this.breadcrumbs[i].state)
      } else if (!isForward) {
        // If this is not forward state, then remove it from breadcrumbs
        this.breadcrumbs = this.breadcrumbs.slice(0, i)

        bc.data.onDestroy?.()
      }
    }

    // console.log('adding state=', state)
    this.addOrUpdateBreadcrumb(state, data)
  }

  getBreadcrumb(name: string) {
    for (let i = this.breadcrumbs.length - 1; i >= 0; i--) {
      const bc = this.breadcrumbs[i]
      if (bc.state.name === name) {
        return bc
      }
    }
  }

  emitEvent(event: Event) {
    for (let i = this.breadcrumbs.length - 1; i >= 0; i--) {
      const bc = this.breadcrumbs[i]
      console.log(bc.data)
      bc.data.meta?.eventSink(event)
    }
  }

  get currentBreadcrumb() {
    const len = this.breadcrumbs.length
    if (len > 0) {
      return toJS(this.breadcrumbs[len - 1])
    }
    return undefined
  }

  get baseBreadcrumb() {
    const len = this.breadcrumbs.length
    if (len > 0) {
      return toJS(this.breadcrumbs[0])
    }
    return undefined
  }

  get baseBreadcrumbName() {
    return this.baseBreadcrumb?.state.name.split(".")[0]
  }

  get prevBreadcrumb() {
    const len = this.breadcrumbs.length
    if (len > 1) {
      return toJS(this.breadcrumbs[len - 2])
    }
    return undefined
  }
}

export default BreadcrumbStore
