import isNumber from "lodash/isNumber"
import omitByDeep from "./omitByDeep"

function omitFields<T>(data: T, prefixes: string[]) {
  return omitByDeep(
    data,
    (_: unknown, key: string) =>
      !isNumber(key) && prefixes.find((p) => key.startsWith(p))
  ) as T
}

export default omitFields
