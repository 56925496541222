import { makeAutoObservable, flow, toJS, when } from "mobx"
import { createPatch } from "rfc6902"
import { pickBy, omitBy } from "lodash"
import * as Sentry from "@sentry/react"
import { AxiosRequestHeaders, AxiosResponse } from "axios"

import Api, {
  GET_ONE,
  PATCH,
  CREATE,
  DELETE,
  DELETE_MANY,
  Fields,
  NetworkError,
} from "../api"
import { Data, UserStore } from "."
import omitFields from "./tools/omitFields"

// Special resource fields:
//   - "_" - used for linked resource fields. Will not be persisted on resource save.
//   - "$$" - used for cached fields, that will be recomputed on each resource form mount,
//          and will not be preserved when form goes in the breadcrumb history. Will not be persisted on resource save.
//   - "$_" - used for cached fields, that will be preserved when form goes in the breadcrumb history. Will not be persisted on resource save.

export type SaveOptions = {
  returnFull?: boolean
  skipStore?: boolean
}

export class ItemStore<T extends Data = Data> {
  api: Api
  userStore: UserStore
  resource: string
  unsubFuncs?: (() => void)[]

  data?: T
  eTag?: string

  constructor(
    api: Api,
    userStore: UserStore,
    resource: string,
    private onLoading?: (loading: boolean) => void
  ) {
    this.api = api
    this.userStore = userStore

    this.resource = resource
    this.reset(undefined)

    makeAutoObservable(this, {
      api: false,
      userStore: false,
      resource: false,
      unsubFuncs: false,
    })
  }

  fetch = flow(function* (
    this: ItemStore<T>,
    id: string,
    fields?: Fields,
    doStore = true
  ) {
    let retries = 2
    while (retries--) {
      try {
        this.onLoading?.(true)
        const result = yield this.api.call(GET_ONE, this.resource, {
          id,
          fields,
        }).promise
        this.onLoading?.(false)
        if (doStore) {
          this.data = result.data as T
        }
        this.eTag = result.headers.etag
        return result.data as T
      } catch (error) {
        const err = error as NetworkError

        this.onLoading?.(false)

        if (
          err.response &&
          err.response.data.code &&
          err.response.data.message
        ) {
          if (err.response.data.code < 500) {
            err.message = err.response.data.message
            err.issues = err.response.data?.issues
          }
        }

        const status = err.response && err.response.status
        switch (status) {
          case 304:
            //Not modified
            break

          case 401:
            if (retries > 0) {
              const success = yield this.api.refreshToken()
              if (!success) {
                this.userStore.setCurrentUser(undefined)
                yield when(() => this.userStore.currentUser !== undefined)
              }
            } else {
              throw error
            }
            break

          case 403:
            throw err

          default:
            console.dir(err)
            Sentry.withScope((scope) => {
              if (err.response && err.response.data) {
                scope.setExtra("Data", err.response.data)
              }
              Sentry.captureException(err)
            })
            throw err
        }
      }
    }
  })

  save = (data: T, options?: SaveOptions) => {
    if (data.id) {
      return this.update(data, options)
    } else {
      return this.create(data, options?.skipStore)
    }
  }

  private create = flow(function* (
    this: ItemStore<T>,
    data: T,
    skipStore = false
  ) {
    // if (this.appStore.empty) {
    //   this.appStore.user = undefined
    //   return
    // }

    // Save all sub-resources for later use
    const subResources = pickBy<T>(data, (_, key) => key.charAt(0) === "_")
    // Remove all sub-resources
    let newData = omitBy<T>(data, (_, key) => key.charAt(0) === "_")
    // Remove all cached fields starting with "$$"
    newData = omitFields(newData, ["_", "$$", "$_"])

    let retries = 2
    while (retries--) {
      try {
        this.onLoading?.(true)
        const result = yield this.api.call(CREATE, this.resource, {
          data: newData,
        }).promise
        this.onLoading?.(false)
        this.eTag = result.headers.etag

        if (!skipStore) {
          this.data = Object.assign(subResources, result.data as T)
        }

        return Object.assign(subResources, result.data as T)
      } catch (error) {
        const err = error as NetworkError

        this.onLoading?.(false)

        if (
          err.response &&
          err.response.data.code &&
          err.response.data.message
        ) {
          if (err.response.data.code < 500) {
            err.message = err.response.data.message
            err.issues = err.response.data?.issues
          }
        }

        const status = err.response && err.response.status
        switch (status) {
          case 401:
            if (retries > 0) {
              const success = yield this.api.refreshToken()
              if (!success) {
                this.userStore.setCurrentUser(undefined)
                yield when(() => this.userStore.currentUser !== undefined)
              }
            } else {
              throw error
            }
            break

          case 403:
            throw err

          default:
            console.dir(err)
            Sentry.withScope((scope) => {
              if (err.response && err.response.data) {
                scope.setExtra("Data", err.response.data)
              }
              Sentry.captureException(err)
            })
            throw err
        }
      }
    }
  })

  private update = flow(function* (
    this: ItemStore<T>,
    data: T,
    options?: SaveOptions
  ) {
    // Save all sub-resources for later use
    const subResources = pickBy(data, (_, key) => key.charAt(0) === "_")
    // Remove all sub-resources
    let newData = omitBy(data, (_, key) => key.charAt(0) === "_")
    // Remove all cached fields starting with "$$"
    newData = omitFields(newData, ["_", "$$", "$_"])

    // Remove all sub-resources
    let origData = omitBy(toJS(this.data), (_, key) => key.charAt(0) === "_")
    // Remove all cached fields starting with "$$"
    origData = omitFields(origData, ["_", "$$", "$_"])

    const patch = createPatch(origData, newData)
    if (patch.length === 0) {
      console.log("empty patch")
      return data
    }

    let retries = 2
    while (retries--) {
      try {
        const headers: AxiosRequestHeaders = {}
        if (this.eTag) {
          headers["If-Match"] = this.eTag
        }

        headers["Content-Type"] = "application/json-patch+json"

        if (!options?.returnFull) {
          headers.Prefer = "return=minimal"
        }

        this.onLoading?.(true)
        const result: AxiosResponse<T> = yield this.api.call(
          PATCH,
          this.resource,
          {
            id: data.id,
            data: patch,
            headers,
          }
        ).promise
        this.onLoading?.(false)

        this.eTag = result.headers.etag

        if (result.status !== 204) {
          // Imbune any new server data, with original data sub-resources
          data = Object.assign(subResources, result.data as T)
        } else {
          data = omitFields(data, ["$$", "$_"])
        }

        if (!options?.skipStore) {
          this.data = data
        }

        return data
      } catch (error) {
        const err = error as NetworkError

        this.onLoading?.(false)

        if (
          err.response &&
          err.response.data.code &&
          err.response.data.message
        ) {
          if (err.response.data.code < 500) {
            err.message = err.response.data.message
            err.issues = err.response.data?.issues
          }
        }

        const status = err.response && err.response.status
        switch (status) {
          case 401:
            if (retries > 0) {
              const success = yield this.api.refreshToken()
              if (!success) {
                this.userStore.setCurrentUser(undefined)
                yield when(() => this.userStore.currentUser !== undefined)
              }
            } else {
              throw error
            }
            break

          case 403:
            throw err

          case 412:
            err.message =
              "This document is already updated by other user. Please refresh the document, re-apply your changes, then save again."
            throw err

          default:
            console.dir(err)
            Sentry.withScope((scope) => {
              if (err.response && err.response.data) {
                scope.setExtra("Data", err.response.data)
              }
              Sentry.captureException(err)
            })
            throw err
        }
      }
    }
  })

  delete = flow(function* (this: ItemStore<T>, ids: string[]) {
    let retries = 2
    while (retries--) {
      try {
        this.onLoading?.(true)
        yield this.api.call(DELETE_MANY, this.resource, {
          ids,
        }).promise
        this.onLoading?.(false)
        return
      } catch (error) {
        const err = error as NetworkError

        this.onLoading?.(false)

        if (
          err.response &&
          err.response.data.code &&
          err.response.data.message
        ) {
          if (err.response.data.code < 500) {
            err.message = err.response.data.message
            err.issues = err.response.data?.issues
          }
        }

        const status = err.response && err.response.status
        switch (status) {
          case 401:
            if (retries > 0) {
              const success = yield this.api.refreshToken()
              if (!success) {
                this.userStore.setCurrentUser(undefined)
                yield when(() => this.userStore.currentUser !== undefined)
              }
            } else {
              throw error
            }
            break

          case 403:
            throw err

          default:
            console.dir(err)
            Sentry.withScope((scope) => {
              if (err.response && err.response.data) {
                scope.setExtra("Data", err.response.data)
              }
              Sentry.captureException(err)
            })
            throw err
        }
      }
    }
  })

  remove = flow(function* (this: ItemStore<T>, id: string) {
    let retries = 2
    while (retries--) {
      try {
        this.onLoading?.(true)
        yield this.api.call(DELETE, this.resource, {
          id,
        }).promise
        this.onLoading?.(false)
        return
      } catch (error) {
        const err = error as NetworkError

        this.onLoading?.(false)

        if (
          err.response &&
          err.response.data.code &&
          err.response.data.message
        ) {
          if (err.response.data.code < 500) {
            err.message = err.response.data.message
            err.issues = err.response.data?.issues
          }
        }

        const status = err.response && err.response.status
        switch (status) {
          case 401:
            if (retries > 0) {
              const success = yield this.api.refreshToken()
              if (!success) {
                this.userStore.setCurrentUser(undefined)
                yield when(() => this.userStore.currentUser !== undefined)
              }
            } else {
              throw error
            }
            break

          case 403:
            throw err

          default:
            console.dir(err)
            Sentry.withScope((scope) => {
              if (err.response && err.response.data) {
                scope.setExtra("Data", err.response.data)
              }
              Sentry.captureException(err)
            })
            throw err
        }
      }
    }
  })

  reset(value: T | undefined) {
    this.data = value
  }

  destroy() {
    if (this.unsubFuncs) {
      this.unsubFuncs.forEach((f) => f())
      delete this.unsubFuncs
    }
  }

  get empty() {
    return !this.data
  }
}
