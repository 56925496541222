export const GET_LIST = "GET_LIST"
export const GET_ONE = "GET_ONE"
export const CREATE = "CREATE"
export const UPDATE = "UPDATE"
export const UPDATE_MANY = "UPDATE_MANY"
export const PATCH = "PATCH"
export const DELETE = "DELETE"
export const DELETE_MANY = "DELETE_MANY"

export type Filter = {
  [x: string]:
    | Filter
    | Array<Filter>
    | string
    | number
    | string[]
    | boolean
    | null
    | undefined
}

export type Pagination = {
  page: number
  perPage: number
}

export type Sort = {
  name: string
  direction: "desc" | "asc"
}

export type Field = {
  name: string
  pagination?: Pagination
  sort?: Sort[]
  fields?: (Field | string)[]
  filter?: Filter
}

export type Fields = (Field | string)[]

function formatFields(fields: Fields) {
  const flds = []
  for (const f of fields) {
    if (typeof f === "string") {
      flds.push(f)
    } else {
      let str = ""
      if (f.name) {
        str += f.name
      } else {
        throw Error("embedded resource needs name")
      }

      const tmp = []
      if (f.pagination) {
        const { page, perPage } = f.pagination
        tmp.push(`skip:${page * perPage},limit:${perPage}`)
      }
      if (f.sort) {
        tmp.push(
          `sort:"${f.sort
            .map(
              (item) => `${item.direction === "desc" ? "-" : ""}${item.name}`
            )
            .join(",")}"`
        )
      }
      if (f.filter) {
        tmp.push(`filter:'${JSON.stringify(f.filter)}'`)
      }
      if (tmp.length > 0) {
        str += "(" + tmp.join(",") + ")"
      }

      if (f.fields) {
        str += "{" + formatFields(f.fields) + "}"
      }

      flds.push(str)
    }
  }

  return flds.join(",")
}

type Query = {
  skip?: number
  limit?: number
  sort?: string
  filter?: Filter
  fields?: string
}

export type QueryParams = {
  pagination?: Pagination
  sort?: Sort[]
  fields?: Fields
  filter?: Filter
}

export function formatQuery(params: QueryParams) {
  const query: Query = {}

  if (params.pagination) {
    const { page, perPage } = params.pagination
    query.skip = page * perPage
    query.limit = perPage
  }

  if (params.sort) {
    query.sort = params.sort
      .filter((item) => item)
      .map((item) => `${item.direction === "desc" ? "-" : ""}${item.name}`)
      .join(",")
  }
  if (params.filter) {
    query.filter = params.filter
  }
  if (params.fields) {
    query.fields = formatFields(params.fields)
  }
  return query
}

export const getFilterValue = (
  filters: Filter | undefined,
  filterNames: string[]
) => {
  for (const f in filters) {
    if (filterNames.indexOf(f) !== -1) {
      return {
        name: f,
        value: filters?.[f],
      }
    }
  }
  return undefined
}

// import { AxiosRequestHeaders } from "axios"
// interface QueryParamss {
//   headers?: AxiosRequestHeaders
//   timeout?: number
// }

// interface GetListParams extends QueryParamss {
//   type: "GET_LIST"
//   ids?: string[]
//   pagination?: Pagination
//   skip?: number
//   limit?: number
//   sort?: Sort[]
//   fields?: Fields
//   filter?: Filter
// }

// interface GetOneParams extends QueryParamss {
//   type: "GET_ONE"
//   id: string
//   fields?: Fields
//   filter?: Filter
// }

// interface UpdateParams extends QueryParamss {
//   type: "UPDATE"
//   id: string
//   data: Record<string, unknown>
// }

// interface UpdateManyParams extends QueryParamss {
//   type: "UPDATE_MANY"
//   ids: string[]
//   data: Record<string, unknown>
// }

// interface PatchParams extends QueryParamss {
//   type: "PATCH"
//   id: string
//   data: Record<string, unknown>
// }

// interface CreateParams extends QueryParamss {
//   type: "CREATE"
//   data: Record<string, unknown>
// }

// interface DeleteParams extends QueryParamss {
//   type: "DELETE"
//   id: string
// }

// interface DeleteManyParams extends QueryParamss {
//   type: "DELETE_MANY"
//   ids: string[]
// }
