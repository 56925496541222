import { makeAutoObservable } from "mobx"
import React from "react"

export type MaxWidth = false | "xs" | "sm" | "md" | "lg" | "xl" | undefined
export type SnackVariant = "success" | "warning" | "error" | "info"

export type SnackState = {
  message: string
  variant?: SnackVariant
  duration?: number
}

export type DialogState = {
  type?: "error" | "warning" | "confirm"
  icon?: React.Component
  name?: string
  color?: string
  title?: string
  message?: string | React.Component
  maxWidth?: MaxWidth
  onClose?: (success: boolean | undefined) => void
  showInfo?: boolean
}

export type TopNotification = {
  html: string
  kind: "warning" | "error"
  closedAt?: Date
}

export class UiStore {
  loading = 0
  refreshFunc?: () => void
  snack?: SnackState
  dialog?: DialogState
  topNotification?: TopNotification

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  resetRefreshFunc = (func: (() => void) | undefined) =>
    (this.refreshFunc = func)

  startLoading() {
    this.loading++
  }
  stopLoading() {
    this.loading > 0 ? this.loading-- : (this.loading = 0)
  }
  showSnack(state: SnackState) {
    this.snack = state
  }
  closeSnack() {
    this.snack = undefined
  }

  showErrorDialog(message: string, title?: string, showInfo = true) {
    this.dialog = {
      type: "error",
      color: "red",
      title: title ?? "Error",
      message,
      showInfo,
    }
  }

  showConfirmDialog(
    message: string,
    onClose: (success: boolean | undefined) => void,
    title?: string,
    color?: string,
    maxWidth?: MaxWidth
  ) {
    this.dialog = {
      type: "confirm",
      title: title ?? "Confirm",
      message,
      maxWidth,
      color: color ?? "orange",
      onClose,
    }
  }

  showWarnSaveDialog(
    message: string,
    onClose?: (success: boolean | undefined) => void,
    title?: string,
    color?: string,
    maxWidth?: MaxWidth
  ) {
    this.dialog = {
      type: "warning",
      title: title ?? "Warning",
      message,
      color: color ?? "orange",
      maxWidth,
      onClose,
    }
  }

  closeDialog(success: boolean | undefined) {
    console.log(success)
    this.dialog?.onClose?.(success)
    this.dialog = undefined
  }

  setTopNotification(notif?: TopNotification) {
    this.topNotification = notif
  }
}
