import { MouseEventHandler, useState } from "react"
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from "@mui/material"
import { AccountCircle, Person as PersonIcon } from "@mui/icons-material"

import { useAppStores } from "@/stores"
import { useBaseStores, User } from "../stores"

const UserButton = () => {
  const { userStore } = useBaseStores()
  const { appStore } = useAppStores()
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined)

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(undefined)
  const handleLogout = () => {
    appStore.logout()
  }

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <AccountCircle />
      </IconButton>
      <UserMenu
        anchorEl={anchorEl}
        user={userStore.currentUser!}
        onClose={handleClose}
        onLogout={handleLogout}
      />
    </>
  )
}

export default UserButton

const UserMenu = ({
  anchorEl,
  onClose,
  onLogout,
  user,
}: {
  anchorEl?: Element
  onClose: () => void
  onLogout: () => void
  user: User
}) => {
  const { api } = useBaseStores()
  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      <MenuList style={{ outline: "none" }}>
        <MenuItem sx={{ py: 2 }}>
          <ListItemAvatar>
            <Avatar
              src={user.photoUrl ? api.apiUrl + user.photoUrl : undefined}
              sx={{ width: 48, height: 48 }}
            >
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={user.name} />
        </MenuItem>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}
