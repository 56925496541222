import { makeAutoObservable } from "mobx"
import * as Sentry from "@sentry/react"

export interface User {
  id: string
  name: string
  email: string
  photoUrl: string
  god?: boolean
}

export class UserStore {
  currentUser?: User

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })

    try {
      const currentUser = localStorage.getItem("user")
      if (currentUser) {
        this.currentUser = JSON.parse(currentUser)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  setCurrentUser(user?: User) {
    this.currentUser = user
    if (user) {
      Sentry.setUser({
        id: user.id,
        username: user.name,
        email: user.email,
      })

      localStorage.setItem("user", JSON.stringify(user))
    } else {
      localStorage.removeItem("user")
    }
  }
}
