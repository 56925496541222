import RouteView from "./RouteView"

export { RouteView }

import { createRouter, ActivationFn } from "router5"
import loggerPlugin from "router5-plugin-logger"
import browserPlugin from "router5-plugin-browser"
import isEqual from "lodash/isEqual"

import routes from "@/routes"
import { baseStores, BreadcrumbStore } from "../stores"
import { StateData } from "../stores/BreadcrumbStore"

const breadcrumbs =
  (brStore: BreadcrumbStore) =>
  (): ActivationFn =>
  (toState, fromState, done) => {
    // console.log('state=', toState, fromState)
    const isForward = brStore.getBreadcrumbIndex(toState) === -1
    const state = brStore.focusBreadcrumbOrAdd(
      toState,
      {} as StateData,
      isForward
    )
    // console.log('state 0 =', state)
    if (state) {
      if (
        toState.params.id !== "dummy" &&
        state.name === toState.name &&
        !isEqual(state.params, toState.params)
      ) {
        // console.log('state 1=', toState)
        done()
      } else {
        // console.log('state 2=', state)
        done(undefined, state)
      }
      return
    }
    done()
  }

export default function configureRouter(
  defaultRoute: string,
  useLoggerPlugin = false
) {
  const router = createRouter(routes, { defaultRoute, autoCleanUp: false })
  router.usePlugin(browserPlugin({ useHash: true, forceDeactivate: false }))
  router.useMiddleware(breadcrumbs(baseStores.breadcrumbStore))

  if (useLoggerPlugin) {
    router.usePlugin(loggerPlugin)
  }
  return router
}
