import {
  Typography,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

export const DialogTitle = ({
  children,
  onClose,
  title,
  color,
}: {
  children?: React.ReactNode
  onClose?: () => void
  title?: string
  color?: string
}) => {
  return (
    <MuiDialogTitle
      sx={{
        p: 1,
        backgroundColor: color || "primary.main",
      }}
    >
      <div style={{ width: "calc(100% - 40px)", display: "flex" }}>
        {title ? (
          <Typography variant="h6" style={{ color: "white" }}>
            {title}
          </Typography>
        ) : (
          children
        )}
      </div>
      {onClose && (
        <IconButton
          aria-label="Close"
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: "white",
          }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

export const DialogContent = (props: any) => {
  return <MuiDialogContent sx={{ m: 0, p: 1 }} {...props} />
}

export const DialogActions = (props: any) => {
  return <MuiDialogActions sx={{ m: 0, p: 1 }} {...props} />
}
