//@ts-nocheck
import omitBy from "lodash/omitBy"
import isNil from "lodash/isNil"
import isFunction from "lodash/isFunction"
import isPlainObject from "lodash/isPlainObject"

// from https://github.com/odynvolk/omit-deep-lodash
function omitDeepLodash(input, props, ...args) {
  function omitDeepOnOwnProps(obj) {
    if (typeof input === "undefined") {
      return input
    }

    if (!Array.isArray(obj) && !isPlainObject(obj)) {
      return obj
    }

    if (Array.isArray(obj)) {
      return omitDeepLodash(obj, props)
    }

    const o = {}
    for (const [key, value] of Object.entries(obj)) {
      o[key] = !isNil(value) ? omitDeepLodash(value, props) : value
    }

    return omitBy(o, props)
  }

  if (args.length > 2) {
    props = Array.prototype.slice.call(args).slice(1)
  }

  if (Array.isArray(input)) {
    return input
      .filter((v, k) => {
        if (isFunction(props)) {
          return !props(v, k)
        }
        return true
      })
      .map(omitDeepOnOwnProps)
  }

  return omitDeepOnOwnProps(input)
}

export default omitDeepLodash
