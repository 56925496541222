import React from "react"
import ReactDOM from "react-dom"
import "@fontsource/roboto"
import "@fontsource/roboto/700.css"

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import "./index.css"
import App from "./App"

// Register the Service Worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register(
    import.meta.env.MODE === "production"
      ? "/service-worker.js"
      : "/dev-sw.js?dev-sw"
  )
  console.log("Service Worker Registered")
  // Listen for messages from the Service Worker
  navigator.serviceWorker.addEventListener("message", (event) => {
    const message = event.data
    console.log(message)
    switch (message.type) {
      case "updateRefreshToken":
        localStorage.setItem("auth-refreshToken", message.token)
        break
      case "getRefreshToken":
        console.log("Sending refresh token to Service Worker")
        event.ports[0].postMessage({
          type: "refreshToken",
          token: localStorage.getItem("auth-refreshToken"),
        })
        break
    }
  })
}

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://24dc6252ac974a8fa4fd305ea35e7d2e@o243239.ingest.sentry.io/6531645",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
