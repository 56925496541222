export type Event = {
  type: string
  payload: unknown
}

/// XXX: For more sophisticated use cases
// https://developpaper.com/using-react-hooks-and-eventemitter/
export type Callback = (event: Event) => void

export class EventBus {
  private readonly subs = new Map<string, Callback>()
  subscribe = (key: string, callback: Callback) => {
    this.subs.set(key, callback)
  }
  unsubscribe = (key: string) => {
    this.subs.delete(key)
  }
  emit = (event: Event) => {
    this.subs.forEach((cb) => cb(event))
  }
}
