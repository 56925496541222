import { ReactElement } from "react"

import { RouteConfig } from "@/routes"

const getComponent = (
  path: string,
  routes: RouteConfig[] | undefined
): ReactElement | undefined => {
  const segments = path.split(".")

  if (!routes) {
    return undefined
  }

  if (segments.length > 1) {
    const restSegments = segments.slice(1).join(".")
    const r = routes.find((r) => r.name === segments[0])
    return getComponent(restSegments, r?.children)
  } else if (segments.length > 0) {
    const r = routes.find((r) => r.name === segments[0])
    return r?.component
  }
}

export default getComponent
