import { Permission } from "./permissions"

export const PermissionDefs: Record<string, Record<string, Permission[]>> = {
  admin: {
    bankForexes: [
      {
        description: "Write Bank Forexes",
        rules: [{ op: "write", path: "*" }],
      },
    ],
    contracts: [
      {
        description: "Create New Contracts",
        rules: [{ op: "create", path: "*" }],
      },
      {
        description: "Write Draft \u0026 Active Contracts only",
        rules: [
          { op: "write", path: "*" },
          {
            op: "compare",
            path: "status",
            compareValue: ["10-draft", "20-active"],
          },
        ],
      },
    ],
    customers: [
      { description: "Write Customers", rules: [{ op: "write", path: "*" }] },
    ],
    files: [
      { description: "Read Files", rules: [{ op: "read", path: "*" }] },
      { description: "Create Files", rules: [{ op: "create", path: "*" }] },
      { description: "Write Files", rules: [{ op: "write", path: "*" }] },
    ],
    forexes: [
      { description: "Write Forexes", rules: [{ op: "write", path: "*" }] },
    ],
    invoices: [
      { description: "Read Invoices", rules: [{ op: "read", path: "*" }] },
      {
        description: "Write Invoices",
        rules: [
          { op: "write", path: "*" },
          {
            op: "compare",
            path: "status",
            compareValue: ["10-waiting", "20-paid", "40-paid-late"],
            compareOrig: ["10-waiting", "30-overdue"],
          },
        ],
      },
    ],
    offers: [
      { description: "Read Offers", rules: [{ op: "read", path: "*" }] },
      {
        description: "Write Offers",
        rules: [
          { op: "write", path: "*" },
          {
            op: "compare",
            path: "info.status",
            compareOrig: [
              "10-pending",
              "19-conditionally-approved",
              "20-approved",
            ],
          },
        ],
      },
      {
        description: "Change Offers Status",
        rules: [
          { op: "write", path: "updatedAt" },
          { op: "write", path: "info.status" },
          {
            op: "compare",
            path: "info.status",
            compareValue: [
              "39-conditionally-declined",
              "40-declined",
              "50-withdraw",
            ],
            compareOrig: [
              "10-pending",
              "19-conditionally-approved",
              "20-approved",
            ],
          },
        ],
      },
    ],
    payments: [
      { description: "Read Payments", rules: [{ op: "read", path: "*" }] },
      {
        description: "Write Payments",
        rules: [
          { op: "write", path: "status" },
          { op: "write", path: "fileIds" },
          { op: "write", path: "refunds" },
          { op: "write", path: "payments" },
          { op: "write", path: "updatedAt" },
          { op: "write", path: "paymentsTotal" },
          { op: "create", path: "transactionOps" },
          { op: "write", path: "nextPaymentDueAt" },
          { op: "write", path: "lastPaymentDueAt" },
          { op: "write", path: "nextPaymentAmount" },
        ],
      },
    ],
    purchaseOrders: [
      {
        description: "Create Purchase Orders",
        rules: [{ op: "create", path: "*" }],
      },
    ],
    receipts: [
      { description: "Read Receipts", rules: [{ op: "read", path: "*" }] },
      { description: "Write Notes", rules: [{ op: "write", path: "notes" }] },
    ],
    reports: [
      { description: "Read Reports", rules: [{ op: "read", path: "*" }] },
    ],
    suppliers: [
      { description: "Write Suppliers", rules: [{ op: "write", path: "*" }] },
    ],
    systemSettings: [
      {
        description: "Read System Settings",
        rules: [{ op: "read", path: "*" }],
      },
    ],
    transactions: [
      { description: "Write Transactions", rules: [{ op: "read", path: "*" }] },
    ],
    users: [
      { description: "Read Users", rules: [{ op: "read", path: "*" }] },
      {
        description: "Update own Password",
        rules: [
          { op: "not-write", path: "*" },
          { op: "compare", path: "_id$", compareTag: "currentUserId" },
          { op: "write", path: "password" },
        ],
      },
      {
        description: "Write a Users",
        rules: [
          { op: "write", path: "*" },
          {
            op: "compare",
            path: "role",
            compareValue: "admin",
            compareNegate: true,
          },
          {
            op: "compare",
            path: "role",
            compareValue: "owner",
            compareNegate: true,
          },
        ],
      },
    ],
  },
  backoffice: {
    bankForexes: [
      { description: "Read Bank Forexes", rules: [{ op: "read", path: "*" }] },
    ],
    contracts: [
      { description: "Read Contracts", rules: [{ op: "read", path: "*" }] },
    ],
    customers: [
      { description: "Write Customers", rules: [{ op: "write", path: "*" }] },
    ],
    files: [
      { description: "Read Files", rules: [{ op: "read", path: "*" }] },
      { description: "Create Files", rules: [{ op: "create", path: "*" }] },
      { description: "Write Files", rules: [{ op: "write", path: "*" }] },
    ],
    forexes: [
      { description: "Read Forexes", rules: [{ op: "read", path: "*" }] },
    ],
    invoices: [
      { description: "Read Invoices", rules: [{ op: "read", path: "*" }] },
    ],
    offers: [
      { description: "Read Offers", rules: [{ op: "read", path: "*" }] },
      { description: "Write Offers", rules: [{ op: "write", path: "*" }] },
    ],
    payments: [
      { description: "Read Payments", rules: [{ op: "read", path: "*" }] },
      {
        description: "Write Payments",
        rules: [
          { op: "write", path: "fileIds" },
          { op: "write", path: "updatedAt" },
          { op: "write", path: "paymentsTotal" },
          { op: "create", path: "transactionOps" },
          { op: "write", path: "nextPaymentDueAt" },
          { op: "create", path: "payments.*.parts" },
          { op: "not-write", path: "payments.*.parts.*.amount" },
        ],
      },
    ],
    receipts: [
      { description: "Read Receipts", rules: [{ op: "read", path: "*" }] },
    ],
    suppliers: [
      { description: "Read Suppliers", rules: [{ op: "read", path: "*" }] },
    ],
    systemSettings: [
      {
        description: "Read System Settings",
        rules: [{ op: "read", path: "*" }],
      },
    ],
    transactions: [
      { description: "Write Transactions", rules: [{ op: "read", path: "*" }] },
    ],
    users: [
      { description: "Read Users", rules: [{ op: "read", path: "*" }] },
      {
        description: "Update own Password",
        rules: [
          { op: "not-write", path: "*" },
          { op: "compare", path: "_id$", compareTag: "currentUserId" },
          { op: "write", path: "password" },
        ],
      },
    ],
  },
  clerk: {
    bankForexes: [
      { description: "Read Bank Forexes", rules: [{ op: "read", path: "*" }] },
    ],
    contracts: [
      { description: "Read Contracts", rules: [{ op: "read", path: "*" }] },
    ],
    customers: [
      { description: "Read Customers", rules: [{ op: "read", path: "*" }] },
    ],
    files: [
      { description: "Read Files", rules: [{ op: "read", path: "*" }] },
      { description: "Create Files", rules: [{ op: "create", path: "*" }] },
      { description: "Write Files", rules: [{ op: "write", path: "*" }] },
    ],
    forexes: [
      { description: "Read Forexes", rules: [{ op: "read", path: "*" }] },
    ],
    invoices: [
      { description: "Read Invoices", rules: [{ op: "read", path: "*" }] },
    ],
    offers: [
      { description: "Read Offers", rules: [{ op: "read", path: "*" }] },
    ],
    payments: [
      { description: "Read Payments", rules: [{ op: "read", path: "*" }] },
    ],
    receipts: [
      { description: "Read Receipts", rules: [{ op: "read", path: "*" }] },
    ],
    reports: [
      { description: "Read Reports", rules: [{ op: "read", path: "*" }] },
    ],
    suppliers: [
      { description: "Read Suppliers", rules: [{ op: "read", path: "*" }] },
    ],
    systemSettings: [
      {
        description: "Read System Settings",
        rules: [{ op: "read", path: "*" }],
      },
    ],
    transactions: [
      { description: "Read Transactions", rules: [{ op: "read", path: "*" }] },
    ],
    users: [
      { description: "Read Users", rules: [{ op: "read", path: "*" }] },
      {
        description: "Update own Password",
        rules: [
          { op: "not-write", path: "*" },
          { op: "compare", path: "_id$", compareTag: "currentUserId" },
          { op: "write", path: "password" },
        ],
      },
    ],
  },
  officer: {
    bankForexes: [
      { description: "Read Bank Forexes", rules: [{ op: "read", path: "*" }] },
    ],
    contracts: [
      { description: "Read Contracts", rules: [{ op: "read", path: "*" }] },
    ],
    customers: [
      { description: "Read Customers", rules: [{ op: "read", path: "*" }] },
    ],
    files: [
      { description: "Read Files", rules: [{ op: "read", path: "*" }] },
      { description: "Create Files", rules: [{ op: "create", path: "*" }] },
      {
        description: "Write own Files",
        rules: [
          { op: "write", path: "*" },
          { op: "compare", path: "userId", compareTag: "currentUserId" },
        ],
      },
    ],
    forexes: [
      { description: "Read Forexes", rules: [{ op: "read", path: "*" }] },
    ],
    invoices: [
      { description: "Read Invoices", rules: [{ op: "read", path: "*" }] },
    ],
    offers: [
      { description: "Read Offers", rules: [{ op: "read", path: "*" }] },
      {
        description: "Write own Offers",
        rules: [
          { op: "write", path: "*" },
          { op: "not-write", path: "newTransactions" },
          {
            op: "compare",
            path: "info.status",
            compareValue: ["10-pending", "50-withdraw"],
          },
          { op: "not-write", path: "info.officerUserId" },
          {
            op: "compare",
            path: "info.officerUserId",
            compareTag: "currentUserId",
          },
        ],
      },
      {
        description: "Write own Offers, add file",
        rules: [
          { op: "not-write", path: "*" },
          { op: "write", path: "fileIds" },
          { op: "write", path: "updatedAt" },
          {
            op: "compare",
            path: "info.officerUserId",
            compareTag: "currentUserId",
          },
        ],
      },
    ],
    payments: [
      { description: "Read Payments", rules: [{ op: "read", path: "*" }] },
    ],
    receipts: [
      { description: "Read Receipts", rules: [{ op: "read", path: "*" }] },
    ],
    reports: [
      {
        description: "Allow Invoices Report",
        rules: [{ op: "read", path: "invoices" }],
      },
      {
        description: "Allow Receipts Report",
        rules: [{ op: "read", path: "receipts" }],
      },
      {
        description: "Allow Age Analysis Report",
        rules: [{ op: "read", path: "ageAnalisys" }],
      },
    ],
    suppliers: [
      { description: "Read Suppliers", rules: [{ op: "read", path: "*" }] },
    ],
    systemSettings: [
      {
        description: "Read System Settings",
        rules: [{ op: "read", path: "*" }],
      },
    ],
    transactions: [
      { description: "Read Transactions", rules: [{ op: "read", path: "*" }] },
    ],
    users: [
      { description: "Read User", rules: [{ op: "read", path: "*" }] },
      {
        description: "Update User",
        rules: [
          { op: "not-write", path: "*" },
          { op: "compare", path: "_id$", compareTag: "currentUserId" },
          { op: "write", path: "password" },
        ],
      },
    ],
  },
}
