import { SvgIcon, SvgIconProps } from "@mui/material"

const icon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path
      d="M192,176c-17.344,0-32-10.976-32-24s14.656-24,32-24c8.704,0,16.832,2.688,22.848,7.616
			c6.848,5.568,16.896,4.512,22.528-2.304c5.568-6.848,4.544-16.928-2.304-22.528c-7.712-6.272-17.056-10.496-27.072-12.768V80
			c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v17.984c-27.52,6.272-48,28-48,54.016c0,30.88,28.704,56,64,56
			c17.344,0,32,10.976,32,24s-14.656,24-32,24c-8.704,0-16.832-2.688-22.848-7.616c-6.88-5.6-16.928-4.544-22.496,2.304
			c-5.6,6.848-4.576,16.928,2.272,22.528c7.712,6.272,17.056,10.496,27.072,12.8V304c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16
			v-17.984c27.52-6.272,48-28,48-54.016C256,201.12,227.296,176,192,176z"
    />
    <path
      d="M446.752,137.856c-0.8-1.952-1.984-3.712-3.456-5.184L315.328,4.704c-1.472-1.472-3.232-2.656-5.184-3.456
			c-1.92-0.8-4-1.248-6.144-1.248H96C78.368,0,64,14.368,64,32v448c0,17.632,14.368,32,32,32h320c17.632,0,32-14.368,32-32V144
			C448,141.856,447.552,139.776,446.752,137.856z M320,54.624L393.376,128H320V54.624z M416,496v-16H96V32h192v96
			c0,17.632,14.368,32,32,32h96v320c0,0,0,0,0.032,0L416,496z"
    />
    <path d="M368,224h-64c-8.832,0-16,7.136-16,16s7.168,16,16,16h64c8.832,0,16-7.168,16-16C384,231.168,376.832,224,368,224z" />
    <path d="M368,288h-64c-8.832,0-16,7.136-16,16s7.168,16,16,16h64c8.832,0,16-7.168,16-16C384,295.168,376.832,288,368,288z" />
    <path d="M368,352H144c-8.832,0-16,7.136-16,16s7.168,16,16,16h224c8.832,0,16-7.168,16-16C384,359.168,376.832,352,368,352z" />

    <path d="M368,416H144c-8.832,0-16,7.136-16,16s7.168,16,16,16h224c8.832,0,16-7.168,16-16C384,423.168,376.832,416,368,416z" />
  </SvgIcon>
)

export default icon
