import { Snackbar, Alert } from "@mui/material"

import { observer } from "mobx-react-lite"

import { useBaseStores } from "../stores"

const Snack = () => {
  const { uiStore } = useBaseStores()
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={uiStore.snack !== undefined}
      onClose={uiStore.closeSnack}
      autoHideDuration={uiStore.snack?.duration || 2000}
    >
      {uiStore.snack && (
        <Alert
          elevation={6}
          variant="filled"
          severity={uiStore.snack?.variant || "success"}
          onClose={uiStore.closeSnack}
        >
          <span>{uiStore.snack?.message}</span>
        </Alert>
      )}
    </Snackbar>
  )
}

export default observer(Snack)
