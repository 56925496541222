import { createContext, useContext } from "react"

import { baseStores } from "@/base/stores"

import AppStore from "./AppStore"

const appStore = new AppStore(
  baseStores.eventBus,
  baseStores.userStore,
  baseStores.uiStore
)

export const appStores = Object.freeze({
  appStore,
})

const storesContext = createContext(appStores)
export const AppStoresProvider = storesContext.Provider

export const useAppStores = () => useContext<typeof appStores>(storesContext)
