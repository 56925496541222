import { createContext, useContext } from "react"

import Api from "../api"
import { ListStore } from "./ListStore"
import { ItemStore } from "./ItemStore"
import BreadcrumbStore from "./BreadcrumbStore"
import { UiStore } from "./UiStore"
import { Data } from "./types"
import { EventBus } from "../events"
import { UserStore } from "./UserStore"

const api = new Api((import.meta.env.VITE_APP_API_URL as string) || "")
const breadcrumbStore = new BreadcrumbStore()
const uiStore = new UiStore()
const eventBus = new EventBus()
const userStore = new UserStore()

const onLoading = (loading: boolean) => {
  loading ? uiStore.startLoading() : uiStore.stopLoading()
}

export const createListStore = <T extends Data>(
  resource: string,
  noIndicator = false
) =>
  new ListStore<T>(
    api,
    userStore,
    resource,
    noIndicator ? undefined : onLoading
  )
export const createItemStore = <T extends Data>(
  resource: string,
  noIndicator = false
) =>
  new ItemStore<T>(
    api,
    userStore,
    resource,
    noIndicator ? undefined : onLoading
  )

export const baseStores = Object.freeze({
  api,
  breadcrumbStore,
  uiStore,
  eventBus,
  userStore,
})

const storesContext = createContext(baseStores)
export const BaseStoresProvider = storesContext.Provider

export const useBaseStores = () => useContext<typeof baseStores>(storesContext)
