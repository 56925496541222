import { useCallback } from "react"
import { Button, Dialog, Typography, Box } from "@mui/material"

import { observer } from "mobx-react-lite"

import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../components/ClosableDialog"

import { useBaseStores } from "@/base/stores"
import { DialogState } from "@/base/stores/UiStore"
import WarningSign from "@/icons/warning-sign.png"

const ModalDialog = () => {
  const { uiStore } = useBaseStores()
  const handleClose = useCallback(
    (_event, reason) =>
      reason !== "backdropClick" && uiStore.closeDialog(undefined),
    [uiStore]
  )

  return (
    <Dialog
      maxWidth={uiStore.dialog?.maxWidth || "md"}
      open={!!uiStore.dialog}
      onClose={handleClose}
    >
      {uiStore.dialog && (
        <DialogBody
          value={uiStore.dialog || {}}
          onClose={uiStore.closeDialog}
        />
      )}
    </Dialog>
  )
}

export default observer(ModalDialog)

const DialogBody = ({
  onClose,
  value,
}: {
  onClose: (success: boolean) => void
  value: DialogState
}) => {
  return (
    <>
      {value.title && (
        <DialogTitle onClose={() => onClose(false)} color={value.color}>
          <Typography
            variant="h6"
            sx={{
              color: "white",
            }}
            display="inline"
          >
            {value.title}
          </Typography>
          {value.icon && value.name && (
            <Box
              sx={{
                flexGrow: 1,
                textAlign: "center",
                fontSize: "1.3em",
              }}
            >
              {value.icon}
              {value.name}
            </Box>
          )}
        </DialogTitle>
      )}
      <DialogContent>
        <Box display="flex" pt={2} mx={2}>
          {value.type === "warning" && (
            <img src={WarningSign} width="64px" height="64px" />
          )}
          <div>
            {value.type === "error" && value.showInfo == true && (
              <>
                <Typography gutterBottom variant="subtitle1" mx={2}>
                  Something went wrong. We have been notified and working on the
                  problem. Try retrying the operation or{" "}
                  <a
                    href="mailto:drago.ivanov@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Contact us</strong>
                  </a>
                  .
                </Typography>
                <br />
              </>
            )}
            {value.message &&
              (typeof value.message === "string" ? (
                <Typography
                  gutterBottom
                  variant="h6"
                  dangerouslySetInnerHTML={{ __html: value.message }}
                  mx={2}
                />
              ) : (
                value.message
              ))}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => onClose(true)}
          color="primary"
        >
          {value.type === "error" ? "Close" : "OK"}
        </Button>
        {value.type === "confirm" && (
          <Button
            variant="contained"
            onClick={() => onClose(false)}
            color="error"
          >
            Cancel
          </Button>
        )}
      </DialogActions>
    </>
  )
}
