export interface Rule {
  op:
    | "none"
    | "read"
    | "write"
    | "create"
    | "compare"
    | "not-read"
    | "not-write"
  path: string
  compareTag?: string
  compareValue?: string | string[]
  compareOrig?: string | string[]
  compareNegate?: boolean
}

export interface Permission {
  description: string
  rules: Rule[]
}

export class Engine {
  private permissions: Record<string, Permission[]> = {}
  private cache: { [key: string]: boolean } = {}

  constructor(permissions: Record<string, Permission[]>) {
    this.permissions = permissions
    this.cache = {}

    for (const resource in this.permissions) {
      for (const permission of this.permissions[resource]) {
        permission.rules = sortRulesBySpecificity(permission.rules).reverse()
      }
    }
  }

  public getPermissions(): Record<string, Permission[]> {
    return this.permissions
  }

  public canRead(resource: string, path: string): boolean {
    const cacheKey = `${resource}:${path}:read`
    if (this.cache[cacheKey] !== undefined) {
      return this.cache[cacheKey]
    }

    if (this.permissions?.[resource]) {
      for (const permission of this.permissions[resource]) {
        let violates = true
        for (const rule of permission.rules) {
          if (pathMatchesRule(rule.path, path)) {
            if (rule.op === "not-read") {
              violates = true
              // Exit on first rule match. Since rules are sorted by specificity,
              // this will be the most specific rule.
              break
            }

            if (
              rule.op === "read" ||
              rule.op === "write" ||
              rule.op === "create"
            ) {
              violates = false
              // Exit on first rule match. Since rules are sorted by specificity,
              // this will be the most specific rule.
              break
            }
          }
        }

        if (violates === false) {
          this.cache[cacheKey] = true
          return true
        }
      }
    }

    this.cache[cacheKey] = false
    return false
  }

  public canWrite(resource: string, path: string): boolean {
    const cacheKey = `${resource}:${path}:write`
    if (this.cache[cacheKey] !== undefined) {
      return this.cache[cacheKey]
    }

    if (this.permissions?.[resource]) {
      for (const permission of this.permissions[resource]) {
        let violates = true
        for (const rule of permission.rules) {
          if (pathMatchesRule(rule.path, path)) {
            if (rule.op === "not-write") {
              violates = true
              // Exit on first rule match. Since rules are sorted by specificity,
              // this will be the most specific rule.
              break
            }

            if (rule.op === "write") {
              violates = false
              // Exit on first rule match. Since rules are sorted by specificity,
              // this will be the most specific rule.
              break
            }
          }
        }

        if (violates === false) {
          this.cache[cacheKey] = true
          return true
        }
      }
    }

    this.cache[cacheKey] = false
    return false
  }

  public canCreate(resource: string, path: string): boolean {
    const cacheKey = `${resource}:${path}:create`
    if (this.cache[cacheKey] !== undefined) {
      return this.cache[cacheKey]
    }

    if (this.permissions?.[resource]) {
      for (const permission of this.permissions[resource]) {
        let violates = true
        for (const rule of permission.rules) {
          if (pathMatchesRule(rule.path, path)) {
            if (rule.op === "not-write") {
              violates = true
              // Exit on first rule match. Since rules are sorted by specificity,
              // this will be the most specific rule.
              break
            }

            if (rule.op === "write" || rule.op === "create") {
              violates = false
              // Exit on first rule match. Since rules are sorted by specificity,
              // this will be the most specific rule.
              break
            }
          }
        }

        if (violates === false) {
          this.cache[cacheKey] = true
          return true
        }
      }
    }

    this.cache[cacheKey] = false
    return false
  }

  public canSave(resource: string): boolean {
    const cacheKey = `${resource}:change`
    if (this.cache[cacheKey] !== undefined) {
      return this.cache[cacheKey]
    }

    if (this.permissions?.[resource]) {
      for (const permission of this.permissions[resource]) {
        for (const rule of permission.rules) {
          if (rule.op === "write" || rule.op === "create") {
            return true
          }
        }
      }
    }

    return false
  }
}

export const sortRulesBySpecificity = (rules: Rule[]): Rule[] => {
  return rules
    .map((value) => JSON.parse(JSON.stringify(value)))
    .sort((a, b) => {
      const specificityA = ruleSpecificity(a)
      const specificityB = ruleSpecificity(b)

      // Sort by specificity in descending order
      if (specificityA !== specificityB) {
        return specificityA - specificityB
      }

      // Sort by rule length in ascending order
      return a.path.length - b.path.length
    })
}

const ruleSpecificity = (rule: Rule): number => {
  const segments = rule.path.split(".")

  // Remove empty first segment
  if (segments[0] === "") {
    segments.shift()
  }

  let specificity = 0

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i]
    if (segment === "*") {
      specificity += Math.pow(2, i + 1)
    } else {
      specificity += Math.pow(2, i + 1) + 1
    }
  }

  return specificity
}

const pathMatchesRule = (rulePath: string, path: string): boolean => {
  const ruleParts = rulePath.split(".")
  if (ruleParts[0] === "") {
    ruleParts.shift()
  }
  const pathParts = path.split(".")
  if (pathParts[0] === "") {
    pathParts.shift()
  }

  const rulePartsLen = ruleParts.length
  const pathPartsLen = pathParts.length

  if (rulePartsLen > pathPartsLen) {
    return false
  }

  let i = 0
  for (; i < rulePartsLen; i++) {
    const rulePart = ruleParts[i]
    const pathPart = pathParts[i]

    if (rulePart === "*" || pathPart === "*") {
      continue
    }

    if (rulePart === pathPart) {
      continue
    }

    // No match found
    return false
  }

  // If No match found and last part is not a wildcard
  // if (i == rulePartsLen && rulePartsLen !== pathPartsLen && ruleParts[i - 1] !== "*") {
  //   return false
  // }

  return true
}
